import { Popover, PopoverContent } from '@carbon/react';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { X } from 'lucide-react';
import ReactMarkdown from 'react-markdown';

import ChatbotIcon from '#/assets/svgs/ChatbotIcon.svg';
import Logo from '#/assets/svgs/Logo.svg';
import SendIcon from '#/assets/svgs/SendIcon.svg';
import Whatsapp from '#/assets/svgs/Whatsapp.svg';
import { CustomInput } from '#/common/components';
import { CHATBOT_ROLE, MAX_LENGTH_LIMIT } from '#/utils/constants';
import { capitalizeText } from '#/utils/helperFunctions';
import useChatbotWidget from '../hooks/useChatbotWidget';

function ChatbotWidget({ chatbotId }) {
  const {
    chatbotData,
    showPreview,
    handlePreviewToggle,
    messages,
    isSending,
    isFieldSubmit,
    contentRef,
    inputField,
    leadFormik,
    isUploadFileLoading,
    reactions,
    renderLeadForm,
    updateMessages,
    handleChange,
    handleEnterKeyPress,
    submitForm,
    handleReaction,
    handleFileChange,
    handleTelInputChange,
    handleLeadFormClose,
  } = useChatbotWidget(chatbotId);

  const {
    setting: {
      botName = '',
      welcomeMessage = '',
      onlineStatus = '',
      color = '',
      textColor = '',
      whatsappAdded = false,
      whatsappNumber = '',
      position = 'right',
      faqs = [],
    } = {},
    status = 'INACTIVE',
  } = chatbotData?.data || {};

  const chatBotImage = chatbotData?.data?.setting?.chatbotImage?.image || '';

  return (
    chatbotData?.data?.status !== 'DELETED' && (
      <Popover
        className={`booosted4uz1-preview-popover ${status === 'INACTIVE' ? 'booosted4uz1-hide' : ''}`}
        style={position === 'right' ? { right: 50 } : { left: 50 }}
        open={showPreview}
        autoAlign
        align="top-right"
        caret={false}
      >
        <button className="booosted4uz1-btn-preview" style={{ backgroundColor: color }} onClick={handlePreviewToggle}>
          {showPreview ? <X /> : <Logo />}
        </button>
        <PopoverContent className="booosted4uz1-preview-content">
          <div className="booosted4uz1-header" style={{ backgroundColor: color }}>
            <div className="booosted4uz1-avatar-detail">
              <span className="booosted4uz1-image">
                {chatBotImage ? <img src={chatBotImage} alt="Chatbot" /> : <ChatbotIcon />}
              </span>
              <div className="booosted4uz1-detail">
                <h5 style={{ color: textColor }}>{botName}</h5>
                <p style={{ color: textColor }}>{onlineStatus}</p>
              </div>
            </div>
            {whatsappAdded && (
              <a href={`https://wa.me/${whatsappNumber}`} target="_blank">
                <Whatsapp />
              </a>
            )}
          </div>
          <div className="booosted4uz1-content" ref={contentRef}>
            <div className="booosted4uz1-welcome-message">
              <div className="booosted4uz1-image">
                {chatBotImage ? <img src={chatBotImage} alt="Chatbot" /> : <ChatbotIcon />}
              </div>
              <p>{welcomeMessage}</p>
            </div>
            {messages.map(({ text, created, role, id, reaction }, index) => (
              <div
                key={index}
                className={`booosted4uz1-message ${role === CHATBOT_ROLE.USER ? 'booosted4uz1-sender' : 'booosted4uz1-receiver'}`}
              >
                {role !== CHATBOT_ROLE.USER && (
                  <div className="booosted4uz1-image">
                    {chatBotImage ? <img src={chatBotImage} alt="Chatbot" /> : <ChatbotIcon />}
                  </div>
                )}
                <div className="booosted4uz1-text-wrapper">
                  {role === CHATBOT_ROLE.USER ? (
                    <p style={{ backgroundColor: color, color: textColor }}>{text}</p>
                  ) : (
                    <div className="booosted4uz1-markdown-content">
                      <ReactMarkdown>{text}</ReactMarkdown>
                    </div>
                  )}
                  <div className="booosted4uz1-date-reactions">
                    <span>{`${role === CHATBOT_ROLE.ASSISTANT ? 'Livechat' : ''} ${dayjs(created).format('h:mm A')}`}</span>
                    {role === CHATBOT_ROLE.ASSISTANT && (
                      <div className="booosted4uz1-reactions">
                        {reactions?.data?.results?.map(({ id: emojiId, emoji }) => (
                          <span
                            key={emojiId}
                            onClick={handleReaction(id, text, emojiId, reaction)}
                            className={emojiId === reaction ? 'booosted4uz1-active' : ''}
                          >
                            <img src={emoji} alt="emoji" />
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {isSending && (
              <div className="booosted4uz1-message booosted4uz1-receiver booosted4uz1-loading-dots">
                <div className="booosted4uz1-image">
                  {chatBotImage ? <img src={chatBotImage} alt="Chatbot" /> : <ChatbotIcon />}
                </div>
                <p>
                  <span className="booosted4uz1-dots">
                    <span className="booosted4uz1-dot"></span>
                    <span className="booosted4uz1-dot"></span>
                    <span className="booosted4uz1-dot"></span>
                  </span>
                </p>
              </div>
            )}
            {!isEmpty(renderLeadForm?.lead?.fields) && (
              <div className="booosted4uz1-chatbot-lead">
                <div className="booosted4uz1-head">
                  <h4>{renderLeadForm?.lead?.name}</h4>
                  <span onClick={handleLeadFormClose}>
                    <X />
                  </span>
                </div>
                <form onSubmit={leadFormik.handleSubmit} className="booosted4uz1-fields">
                  {renderLeadForm?.lead?.fields?.map(field => {
                    if (!field) return null;

                    const { label, type } = field;

                    if (!label) return null;

                    const fieldType = type?.toLowerCase();
                    const isFileField = fieldType === 'upload';
                    const isNumberField = fieldType === 'number';

                    return (
                      <div key={label} className="booosted4uz1-form-field">
                        <CustomInput
                          key={isFileField ? (isFieldSubmit ? 'uploaded' : 'empty') : label}
                          type={isFileField ? 'file' : isNumberField ? 'tel' : fieldType}
                          name={label}
                          placeholder={capitalizeText(label) || 'Enter value'}
                          onChange={
                            isFileField
                              ? handleFileChange(label)
                              : isNumberField
                                ? handleTelInputChange(label)
                                : leadFormik.handleChange
                          }
                          maxLength={MAX_LENGTH_LIMIT}
                          value={isFileField ? undefined : leadFormik.values[label] || ''}
                          isError={leadFormik.errors[label] && leadFormik.touched[label]}
                          errorMessage={leadFormik.errors[label]}
                          onKeyPress={
                            isNumberField
                              ? e => {
                                if (!/\d/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }
                              : undefined
                          }
                        />
                      </div>
                    );
                  })}
                  <button
                    type="submit"
                    className="booosted4uz1-btn btn-primary"
                    style={{ backgroundColor: color, color: textColor }}
                    disabled={isUploadFileLoading}
                  >
                    Submits
                  </button>
                </form>
              </div>
            )}
          </div>
          <div className="booosted4uz1-footer">
            {!isEmpty(faqs) && (
              <ul className={`booosted4uz1-footer-faqs ${isSending ? 'booosted4uz1-disable' : ''}`}>
                {faqs.map(
                  ({ question, id }) =>
                    question && (
                      <li key={id} className="booosted4uz1-faq" onClick={() => updateMessages(question, id)}>
                        {question}
                      </li>
                    )
                )}
              </ul>
            )}
            <div className="booosted4uz1-message-field">
              <input
                name="inputField"
                type="text"
                placeholder="Write a message"
                value={inputField}
                onChange={handleChange}
                onKeyDown={handleEnterKeyPress}
                disabled={isSending}
              />
              <button
                className={!!inputField.trim() && !isSending ? 'booosted4uz1-active' : ''}
                onClick={submitForm}
                disabled={!inputField.trim() || isSending}
              >
                <SendIcon />
              </button>
            </div>
            <p className="booosted4uz1-powered-by">
              Powered by <span>Booosted.fi</span>
            </p>
          </div>
        </PopoverContent>
      </Popover>
    )
  );
}

export default ChatbotWidget;
