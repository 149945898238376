import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import CacheBuster from 'react-cache-buster';
import { ToastContainer } from 'react-toastify';

import { AppContextProvider } from '#/context';
import { useSentry, useSetupAxios } from '#/services';
import { version } from '../package.json';
import { ChatbotWidget } from './pages/chatbot';

function App() {
  const queryClient = new QueryClient();

  const isCacheBusterEnable = !!Number(import.meta.env.VITE_CACHE_BUSTER);

  const chatbotWidgetDiv = document.getElementById('chatbot-widget');

  useSetupAxios();
  useSentry();

  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <CacheBuster currentVersion={version} isEnabled={isCacheBusterEnable} metaFileDirectory={'/widget'}>
          <ChatbotWidget chatbotId={chatbotWidgetDiv.dataset.chatbotId} />
        </CacheBuster>
      </AppContextProvider>
      <ToastContainer />
    </QueryClientProvider>
  );
}

export default App;
