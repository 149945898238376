export const ACCESS_TOKEN = 'accessToken';

export const COLORS = {
  WHITE: '#ffffff',
  GRAY100: '#D0D5DD',
  SECONDARY: '#5532FA',
};

export const NETWORK_ERROR = 'Network Error';
export const ERROR_STATUS_500 = 5;
export const ERROR_STATUS_401 = 401;
export const ERROR_STATUS_404 = 404;
export const ERROR_MESSAGE = 'Something went wrong!';

export const CHATBOT_ROLE = {
  USER: 'user',
  ASSISTANT: 'assistant',
};

export const MAX_FILE_SIZE = 10 * 1024 * 1024;

export const MAX_LENGTH_LIMIT = 100;
