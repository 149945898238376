import axios from 'axios';
import * as caseConverter from 'change-object-case';
import { startsWith } from 'lodash';
import { toast } from 'react-toastify';

import { ERROR_MESSAGE, ERROR_STATUS_401, ERROR_STATUS_500 } from '#/utils/constants';

const options = { recursive: true, arrayRecursive: true };

let errorDisplayed = false;

export const axiosClient = axios.create({
  withCredentials: true,
});

export function useSetupAxios() {
  axiosClient.interceptors.request.use(config => {
    errorDisplayed = false;

    if (!(config?.data instanceof FormData)) {
      config.headers['Content-Type'] = 'application/json';
      const caseConvertedData = caseConverter.snakeKeys(config?.data, options);
      config.data = caseConvertedData;
    }

    const caseConvertedData = caseConverter.snakeKeys(config.params);
    config.params = caseConvertedData;

    return config;
  });

  axiosClient.interceptors.response.use(
    response => {
      errorDisplayed = false;

      const caseConvertedData = caseConverter.camelKeys(response?.data, options);
      response.data = caseConvertedData;
      return response;
    },
    error => {
      const { response: { status } = {} } = error;

      if (!errorDisplayed) {
        if (status === ERROR_STATUS_401) {
          errorDisplayed = true;
        } else if (startsWith(status, ERROR_STATUS_500)) {
          toast.error(ERROR_MESSAGE);
          errorDisplayed = true;
        }
      }

      return Promise.reject(error);
    }
  );

  return;
}
