export const apiEndpoints = {
  GET_CHATBOT_DETAILS: id => `/chatbot/details/${id}/`,
  SEND_CONVERSATION: '/chatbot/conversation/',
  GET_CONVERSATION_HISTORY: '/chatbot/conversation/',
  CREATE_LEAD: '/chatbot/create-lead/',
  LEAD_RECORD: '/chatbot/lead-record/create/',
  UPLOAD_FILE: '/upload-file/',
  REACTIONS: '/reactions/',
  REACT_REACTION: id => `/chatbot/conversation/${id}/react/`,
};
