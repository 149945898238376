import styles from './CustomInput.module.scss';

function CustomInput({ label, type = 'text', className, onChange, placeholder, isError, errorMessage, ...props }) {
  return (
    <div className={`${styles.customInput} booosted4uz1-custom-input`}>
      {label && <label>{label}</label>}
      <input
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        className={`${styles.formControl} ${isError ? styles.errorInput : undefined} form-control ${className}`}
        {...props}
      />
      {isError && errorMessage && <p>{errorMessage}</p>}
    </div>
  );
}

export default CustomInput;
