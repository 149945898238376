export const toStrCamelCase = str => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
    .join('');
};

export const toCamelCase = obj => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      let camelKey = key
        .replace(/\s(.)/g, match => match.toUpperCase())
        .replace(/\s/g, '')
        .replace(/^(.)/, match => match.toLowerCase());
      return [camelKey, value];
    })
  );
};

export const toSnakeCase = obj => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      let snakeKey = key.toLowerCase().replace(/\s+/g, '_');
      return [snakeKey, value];
    })
  );
};

export const capitalizeText = text => {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};
