import { useEffect, useRef, useState } from 'react';

import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useFormikForm } from '#/common/hooks';
import { apiEndpoints, queryKeys, useGetQuery, usePostMutation, useUpdateMutation } from '#/services';
import { CHATBOT_ROLE, MAX_FILE_SIZE } from '#/utils/constants';
import { toSnakeCase } from '#/utils/helperFunctions';
import { chatbotInputSchema } from '#/utils/ValidationSchema';

function useChatbotWidget(chatbotId) {
  const [showPreview, setShowPreview] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [faqQuestionId, setFaqQuestionId] = useState('');
  const [fileFieldName, setFileFieldName] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [isFieldSubmit, setIsFieldSubmit] = useState(false);
  const [conversationId, setConversationId] = useState(null);

  const contentRef = useRef(null);

  const initialValues = { inputField: '' };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (contentRef.current) {
        contentRef.current.scrollTop = contentRef.current.scrollHeight;
      }
    }, 0);
  };

  const handlePreviewToggle = () => {
    setShowPreview(!showPreview);
    resetForm();
    setIsFieldSubmit(!isFieldSubmit);
    scrollToBottom();
  };

  const onMessageSendSuccess = ({ data: { results } = {} }) => {
    setMessages(results);
    resetForm();
    refetch();
    setIsSending(false);
  };

  const onMessageSendError = ({ response: { data } = {} }) => {
    toast.error(data?.detail || data?.non_field_errors?.[0] || 'Failed to send message');
    setIsSending(false);
  };

  const { mutate: onSendMessage } = usePostMutation({
    url: `${apiEndpoints.SEND_CONVERSATION}?uuid=${chatbotId}`,
    onSuccess: onMessageSendSuccess,
    onError: onMessageSendError,
  });

  const onError = ({ response: { data } } = {}) => {
    const { detail, non_field_errors, image } = data || {};

    const errorMessage = image[0] || detail || non_field_errors[0] || 'An error occurred';

    toast.error(errorMessage);
  };

  const { data: chatbotData } = useGetQuery({
    key: queryKeys.CHATBOT_DETAIL,
    url: apiEndpoints.GET_CHATBOT_DETAILS(chatbotId),
    options: {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!chatbotId,
    },
  });

  const { data: reactions } = useGetQuery({
    key: queryKeys.REACTIONS,
    url: apiEndpoints.REACTIONS,
    options: {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!chatbotId,
    },
  });

  const { data: conversationHistory, refetch } = useGetQuery({
    key: queryKeys.CONVERSATION_HISTORY_KEY,
    url: apiEndpoints.GET_CONVERSATION_HISTORY,
    params: { uuid: chatbotId },
    options: {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!chatbotId,
    },
  });

  const onReactSuccess = () => {
    refetch();
    setConversationId(null);
  };

  const onReactError = ({ response: { data } = {} }) => {
    setConversationId(null);

    const { detail, non_field_errors } = data || {};
    const errorMessage = detail || non_field_errors[0] || 'An error occurred';

    toast.error(errorMessage);
  };

  const { mutate: onEmojiReact } = useUpdateMutation({
    url: apiEndpoints.REACT_REACTION(conversationId),
    onSuccess: onReactSuccess,
    onError: onReactError,
  });

  const { mutate: onLeadSubmit } = usePostMutation({
    url: apiEndpoints.LEAD_RECORD,
    onSuccess: () => {
      toast.success('Thank you for your submission!');
      setIsFieldSubmit(false);
      handleLeadFormClose();
    },
    onError,
  });

  const onUploadFileSuccess = ({ data }) => {
    setUploadedFiles(prev => ({
      ...prev,
      [fileFieldName]: data?.file,
    }));
  };

  const { mutate: uploadFile, isPending: isUploadFileLoading } = usePostMutation({
    url: apiEndpoints.UPLOAD_FILE,
    onSuccess: onUploadFileSuccess,
    onError,
  });

  const updateMessages = (inputField, faqId) => {
    const newMessage = {
      text: inputField,
      created: new Date().toISOString(),
      role: CHATBOT_ROLE.USER,
    };

    setMessages(prev => [...prev, newMessage]);
    setIsSending(true);
    onSendMessage({ payload: { query: inputField } });

    if (faqId) {
      setFaqQuestionId(faqId);
    }
  };

  const onSubmit = values => {
    if (!values.inputField.trim()) return;
    updateMessages(values.inputField);
    resetForm();
    setFaqQuestionId('');
  };

  const {
    values: { inputField },
    handleChange,
    submitForm,
    resetForm,
  } = useFormikForm(initialValues, onSubmit, chatbotInputSchema);

  const handleEnterKeyPress = e => {
    if (e.key === 'Enter' && !isSending && inputField.trim()) {
      e.preventDefault();
      submitForm();
    }
  };

  const handleLeadFormClose = () => {
    setFaqQuestionId('');
  };

  const handleFileChange = fieldName => e => {
    const { files } = e.target;
    if (!files || files.length === 0) return;

    const file = files[0];

    if (file.size > MAX_FILE_SIZE) {
      toast.error('File size exceeds 10MB. Please upload a smaller file.');
      setIsFieldSubmit(!isFieldSubmit);
      setUploadedFiles({});
      leadFormik.setFieldValue(fieldName, '');
      return;
    }

    const formData = new FormData();
    formData.append('file', file, file.name);

    if (fieldName) {
      setFileFieldName(fieldName);
    }

    uploadFile({ payload: formData });

    leadFormik.setFieldValue(fieldName, file);
  };

  const handleTelInputChange = fieldName => e => {
    const value = e.target.value;

    if (value === '' || /^\d+$/.test(value)) {
      leadFormik.setFieldValue(fieldName, value);
    }
  };

  const generateValidationSchema = fields => {
    if (!fields || !fields.length) return Yup.object({});

    const schema = {};

    fields.forEach(field => {
      if (!field) return;

      const { label, type } = field;
      let fieldSchema;

      if (!label) return;

      switch (type?.toLowerCase()) {
      case 'email':
        fieldSchema = Yup.string().email('Invalid email format').required('This field is required');
        break;
      case 'number':
        fieldSchema = Yup.string()
          .matches(/^\d+$/, 'Only numeric characters are allowed')
          .required('This field is required');
        break;
      default:
        fieldSchema = Yup.string().required('This field is required');
      }

      schema[label] = fieldSchema;
    });

    return Yup.object().shape(schema);
  };

  const generateInitialValues = fields => {
    if (!fields || !fields.length) return {};

    const initialValues = {};

    fields.forEach(field => {
      if (field && field.label) {
        initialValues[field.label] = '';
      }
    });

    return initialValues;
  };

  const { setting: { faqs = [] } = {} } = chatbotData?.data || {};

  const renderLeadForm = faqs?.find(item => item?.id === faqQuestionId);

  const onLeadFormSubmit = values => {
    const payload = {
      chatbotLead: renderLeadForm?.lead?.id,
      response: toSnakeCase({
        ...values,
        ...uploadedFiles,
      }),
    };
    onLeadSubmit({ payload });
    resetForm();
    setIsFieldSubmit(true);
    setUploadedFiles({});
  };

  const leadFormik = useFormikForm(
    generateInitialValues(renderLeadForm?.lead?.fields),
    onLeadFormSubmit,
    generateValidationSchema(renderLeadForm?.lead?.fields)
  );

  const handleCopy = text => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Message copied successfully!');
    });
  };

  const handleReaction = (id, text, emojiId, prevReaction) => () => {
    if (emojiId === 3) {
      handleCopy(text);
    } else {
      setConversationId(id);
      if (prevReaction === emojiId) {
        onEmojiReact({ payload: { reaction: null } });
      } else {
        onEmojiReact({ payload: { reaction: emojiId } });
      }
    }
  };

  //effect
  useEffect(() => {
    if (!isEmpty(conversationHistory?.data?.results)) {
      setMessages(conversationHistory.data.results);
    }
  }, [conversationHistory]);

  useEffect(() => {
    if (contentRef.current) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage?.role === CHATBOT_ROLE.USER) {
        contentRef.current.scrollTop = contentRef.current.scrollHeight;
      }
    }
  }, [messages]);

  return {
    chatbotData,
    showPreview,
    handlePreviewToggle,
    messages,
    isSending,
    faqQuestionId,
    uploadedFiles,
    isFieldSubmit,
    contentRef,
    inputField,
    leadFormik,
    isUploadFileLoading,
    reactions,
    renderLeadForm,
    updateMessages,
    handleChange,
    handleEnterKeyPress,
    submitForm,
    handleReaction,
    handleFileChange,
    handleTelInputChange,
    handleLeadFormClose,
  };
}

export default useChatbotWidget;
